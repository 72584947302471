import React from "react"
import Seo from "../../components/seo"
import Deals from "../../components/Content/Deals"

export default props => (
  <React.Fragment>
    <Seo
      title={`Promociones`}
      description={`We have the best Deals in Nicaragua`}
    />
    <Deals location={props.location} langKey={props.pageContext.langKey} />
  </React.Fragment>
)
